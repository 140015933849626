<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">Upload provider data <br><br> <span class="mt-4 font-15 text-danger">The system will alert administrator if there are columns in the incoming excel / csv file not configured in this system</span></h4>
        <form action="" @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Select provider</label>
                <select name="" id="" class="form-control" v-model="providerId" required>
                  <option value="">-- select one --</option>
                  <option :value="provider.id" v-for="provider in providers"> {{provider.name}} </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Select week and year</label>
                <input type="week" class="form-control" v-model="week" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Start reading csv from row</label>
                <input type="number" class="form-control" value="1" min="0" v-model="headerRow" required>
                <div class="text-danger">All csv usually starts from row 3</div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mt-3">
                <vue-dropzone
                    id="file"
                    ref="file"
                    :use-custom-slot="true"
                    :options="dropzoneOptions"
                ><div class="dz-message needsclick">
                  <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                  <h3>Drop csv file from provider</h3>
                </div>
                </vue-dropzone>
              </div>
            </div>

            <div class="col-md-12">
              <div class="text-center">
                <button type="submit" class="btn btn-primary">Submit Now</button>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>

    <template v-if="uploadedCsv">

      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <div class="row">
              <div class="col-md-3">
                <div class="py-1">
                  <!--                <i class="fa fa-users fa-2x text-info"></i>-->
                  <h3 class="text-info"> {{uploadedCsv['total_count']}} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Total uploads
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="py-1">
                  <!--                <i class="fa fa-user-shield fa-2x text-warning"></i>-->
                  <h3 class="text-warning"> {{uploadedCsv['sum_of_income'] | toCurrencyFormat }} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Verkoop
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="py-1">
                  <h3 class="text-success"> {{uploadedCsv['sum_of_cost'] | toCurrencyFormat }} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Inkoop
                  </p>
                </div>
              </div>

              <div class="col-md-3">
                <div class="py-1">
                  <h3 class="text-success"> {{uploadedCsv['total_sum'] | toCurrencyFormat }} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Total
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <b-card>
        <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-dark mr-2" @click="rejectPendingUploadedData"> <i class="fas fa-ban"></i> Reject </button>
            <button class="btn btn-success" @click="acceptPendingUploadedData"> <i class="fas fa-check-circle"></i> Accept </button>
          </div>
        </div>
      </b-card>

      <div class="card">
        <div class="card-body">
          <Sidepanel :drivers="uploadedCsv['drivers_data']" @onDriverSelected="onDriverSelected" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <template v-if="selectedDriverData">

              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">Data for {{selectedDriverData['user']['name']}} {{selectedDriverData['user']['other_names']}}</span>
<!--                  <span class="font-12 badge badge-success p-1 ">Premium Partner</span>-->
                </h5>
              </div>

<!--              <hr>-->
<!--              <h4 class="card-title text-danger">Income</h4>-->
              <!--   Tapped Image should show here  -->
              <div class="row">
                <div class="col-md-12">
                  <table class="table">

                    <tr v-for="data in JSON.parse(selectedDriverData['calculated_income_fields'])">
                      <td>{{data['displayName']}} </td>
                      <td class="text-right"> {{data['value'] | toCurrencyFormat}} </td>
                    </tr>

                  </table>

                </div>
              </div>

<!--              <hr>-->
<!--              <h4 class="card-title text-danger">Cost</h4>-->
              <!--   Tapped Image should show here  -->
              <div class="row">
                <div class="col-md-12">
                  <table class="table">

                    <tr v-for="data in JSON.parse(selectedDriverData['calculated_cost_fields'])">
                      <td>{{data['displayName']}} </td>
                      <td class="text-right"> {{data['value'] | toCurrencyFormat}} </td>
                    </tr>

                  </table>

                </div>
              </div>
<!--              <hr>-->
<!--              <h4 class="card-title text-danger">Additional data</h4>-->
              <!--   Tapped Image should show here  -->
<!--              <div class="row">-->
<!--                <div class="col-md-12">-->
<!--                  <table class="table">-->

<!--                    <tr v-for="data in JSON.parse(selectedDriverData['additional_fields'])">-->
<!--                      <td>{{data['displayName']}} </td>-->
<!--                      <td class="text-right"> {{data['value']}} </td>-->
<!--                    </tr>-->

<!--                  </table>-->

<!--                </div>-->
<!--              </div>-->

              <b-card v-if="selectedDriverData['break_down_headings']">
                <h4 class="card-title">Break down <span class="text-danger">({{selectedDriverData['break_down_body'].length}} transactions for {{selectedDriverData['user']['name']}} )</span> </h4>
                <div class="table-responsive">

                  <table class="table table-striped">
                    <thead>
<!--                      <template v-for=""></template>-->
                      <tr>
                        <th style="width: 100%" v-for="heading in selectedDriverData['break_down_headings']">{{heading}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="row in selectedDriverData['break_down_body']">
                        <td style="width: 100%" v-for="col in row">
                            {{col}}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </b-card>

            </template>

          </div >

        </div>
        <!-- card -->
      </div>

    </template>

  </div>
</template>


<script>
import appConfig from "../../../../../app.config.json";
import vue2Dropzone from "vue2-dropzone";
import Sidepanel from "../providers/sidepanel-uploaded-csv";
import {confirm} from "../../../../utils/functions";
import moment from "moment/moment";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title}`,
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
    Sidepanel,
  },
  computed: {
    providers() {
      return this.$store.getters.GET_PROVIDERS;
    },
    uploadedCsv(){
      return this.$store.getters.GET_UPLOADED_DATA
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: '#',
        maxFilesize: 20.0,
        acceptedFiles: ".csv",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 1,
      },
      providerId: '',
      week: '',
      headerRow: '1',
      selectedDriverData: null,
    };
  },
  methods: {
    handleSubmit(){
      const files = this.$refs.file.getAcceptedFiles();
      if(files.length < 1){
        this.$store.dispatch('error', {
          message: 'Please select a file',
          showSwal: true
        })
        return;
      }

      const file = files[0];

      const formData = new FormData()
      formData.append('file',file)
      formData.append('provider_id', this.providerId);
      formData.append('week', this.week);
      formData.append('heading_row', this.headerRow);

      this.$store.dispatch('uploadProviderData', formData).then(() => {
        this.providerId = ''
        this.week = ''
        this.$refs.file.removeAllFiles();
      })


    },
    fetchPendingUploadedProviderData(){
      this.$store.dispatch('fetchPendingUploadedData')
    },

    rejectPendingUploadedData() {
      confirm("You are about to reject the uploaded file", () => {
        this.$store.dispatch('rejectPendingUploadedData', this.uploadedCsv['campaign_id'])
      })

    },
    acceptPendingUploadedData() {
      // const week =  moment(this.selectedDriverData['start_week']).format('LL');
      confirm("You are about to accept the uploaded file", () => {
        this.$store.dispatch('acceptPendingUploadedData', this.uploadedCsv['campaign_id'])
      })
    },

    onDriverSelected(driverData){
      this.selectedDriverData = driverData
    },

  },
  created() {
    this.$store.dispatch('fetchProviders');
    this.fetchPendingUploadedProviderData();

   // const additional_fields = "[{\"displayName\":\"Wachttijd\",\"value\":7.53}]"
  //   calculated_cost_fields: "[{\"displayName\":\"Ritprijs\",\"fieldType\":\"cost\",\"value\":1082.83,\"weeklyPayment\":true,\"fAdministration\":true},{\"displayName\":\"Dynamisch tarief\",\"fieldType\":\"cost\",\"value\":286.07,\"weeklyPayment\":true,\"fAdministration\":true}]"
  //   calculated_income_fields: "[{\"displayName\":\"Uber Fee\",\"fieldType\":\"income\",\"value\":-349.94,\"weeklyPayment\":true,\"fAdministration\":true},{\"displayName\":\"Belasting over service fee\",\"fieldType\":\"income\",\"value\":-61.87,\"weeklyPayment\":true,\"fAdministration\":true}]"
  },

  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if(data.title==='processing_provider_import'){
        this.$store.dispatch('setProcessingInfo',event.heading);
      }
    });
  }
};
</script>
