<template>
  <div class="inbox-leftbar">
    <!--    <b-button block variant="danger" @click="showModal = true">Compose</b-button>-->
    <!-- start search box -->
    <form class="search-bar">
      <div class="position-relative">
        <input
            type="text"
            class="form-control form-control-light"
            placeholder="Search Driver ..."
            @keyup="onSearch"
        />
        <span class="mdi mdi-magnify"></span>
      </div>
    </form>
    <!-- end search box -->

    <div class="mail-list mt-4">

      <template v-if="isSearching">
        <skeleton :count="5"></skeleton>
      </template>
      <template v-else>

        <template v-for="(driver, index) in filteredDrivers">
          <div class="media mb-4" style="cursor:pointer;" :class="{'active-driver': index === activeIndex}"  @click="driverSelected(driver, index)">
            <div class="position-relative">
              <template v-if="index === activeIndex">
                <b-avatar size="md" variant="light" class="text-dark"
                ><i class="fa fa-check "></i></b-avatar
                >
              </template>
              <template v-else>
                <b-avatar size="md" variant="dark" class="text-light"
                ><span class="">{{ driver['user']['name'] | initials }}</span></b-avatar
                >
              </template>

            </div>
            <div class="media-body">
              <h5 class="mt-0 mb-0 ml-2 font-14">
                {{driver['user']['name']}} {{driver['user']['other_names']}}
<!--                <template v-if="driver.countPendingFiles && driver.countPendingFiles > 0">-->
<!--                  <span class="badge badge-soft-danger float-right ml-2"  @click="driverSelected(driver, index)">{{driver.countPendingFiles}}</span>-->
<!--                </template>-->
              </h5>
              <p class="mt-1 mb-0 ml-2 text-muted font-14">
                <span class="w-75">status: {{driver['user']['status']}}</span>
              </p>
            </div>
          </div>
        </template>

      </template>


    </div>


  </div>
</template>


<script>

import { Skeleton } from 'vue-loading-skeleton';


/**
 * Side-panel component
 */
export default {

  components:{
    Skeleton
  },
  props: ['drivers'],
  data() {
    return {
      activeIndex: 0,
      filteredDrivers: [],
      isSearching: false
    };
  },
  methods: {
    onSearch(event){
      console.log(event.target.value)
      let value = event.target.value;
      if(value === ''){
        this.filteredDrivers = this.drivers;
      }
      this.filteredDrivers = this.filteredDrivers.filter(e => e['user']['name'].toLowerCase().includes(value.toLowerCase()) || (e['user']['other_names'] && e['user']['other_names'].toLowerCase().includes(value.toLowerCase())) )
      this.driverSelected(this.drivers[0], 0);
    },
    driverSelected(driver, index){
      this.activeIndex = index;
      this.$emit('onDriverSelected', driver)
    }
  },
  created() {
    if(this.drivers){
        if(this.drivers.length > 0){
          this.filteredDrivers = this.drivers;
          this.driverSelected(this.drivers[0], 0);
        }
    }
  },
};
</script>

<style scoped>
.active-driver {
  /*background-color: #f3f7f9;*/
  /*border-color: #f3f7f9;*/
  /*padding-top: 10px;*/
  /*padding-bottom: 10px;*/
  /*!*padding-left: 1px;*!*/
  /*!*padding-right: 1px;*!*/
  /*border-radius: 5px;*/
}
</style>